import React, { useEffect } from "react";
import CTABtnModule from "gatsby-theme-starberry-lomondgroup/src/modules/cta_btn_module";
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';


const BannerBtn = ({banner_section, strapi_id}) => {

  const BannerBtnQuery = gql(`
    query BannerBtnQuery($id: ID!) {
      page(id:$id) {
        banner_section{
          button_cta_3{
            theme
            target
            label
            custom_link
            id
            menu_item {
              slug
              parent {
                slug
                parent {
                  slug
                  parent {
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
 `)

  const { data, loading, error } = useQuery(BannerBtnQuery, {
    variables: {
        id: strapi_id
    }
  });

  //console.log("strapi_id", strapi_id, data);

  return (
    <>
        {(banner_section?.button_cta_1?.label || banner_section?.button_cta_2?.label || data?.page?.banner_section?.button_cta_3?.label) && 
        <div className="btn-wrapper">
            {banner_section?.button_cta_1?.label && 
              <CTABtnModule button_cta={banner_section?.button_cta_1}/>
            }

            {banner_section?.button_cta_2?.label && 
              <CTABtnModule button_cta={banner_section?.button_cta_2}/>
            }

            {data?.page?.banner_section?.button_cta_3?.label && 
              <CTABtnModule button_cta={data?.page?.banner_section?.button_cta_3}/>
            }
        </div>
        }
    </>

  );
};
export default BannerBtn;
